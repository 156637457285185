// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
;globalThis["_sentryRewritesTunnelPath"] = "/issues-tunnel";globalThis["SENTRY_RELEASE"] = {"id":"c750099f65485d68cf2921ec709869ff76530136"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import * as Sentry from "@sentry/nextjs";
import Cohere from "cohere-js";
import CohereSentry from "cohere-sentry";
import posthog from "posthog-js";

const DEV = process.env.NODE_ENV === "development";
const SENTRY_DSN =
  process.env.SENTRY_DSN ||
  process.env.NEXT_PUBLIC_SENTRY_DSN ||
  "https://93cd414cbd1e4ed9b124778a8c81b2e4@o427213.ingest.sentry.io/5370964";
const SENTRY_ORG_SLUG = process.env.SENTRY_ORG_SLUG || process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG;
const COHERE_API_KEY = process.env.NEXT_PUBLIC_COHERE_API_KEY;
if (COHERE_API_KEY) {
  Cohere.init(COHERE_API_KEY, { segmentIntegration: true });
}
const SENTRY_ENV = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_ENVIRONMENT;

Sentry.init({
  dsn: SENTRY_DSN,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // We sometimes want a separate environment for Sentry to tell different deployments apart
  environment: SENTRY_ENV,

  // Ignoring errors that are from extensions and 3rd party scripts, copied from https://docs.sentry.io/platforms/javascript/configuration/filtering/
  ignoreErrors: [
    // Random plugins/extensions
    "top.GLOBALS",
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    "originalCreateNotification",
    "canvas.contentDocument",
    "MyApp_RemoveAllHighlights",
    "http://tt.epicplay.com",
    "Can't find variable: ZiteReader",
    "jigsaw is not defined",
    "ComboSearch is not defined",
    "http://loading.retry.widdit.com/",
    "atomicFindClose",
    // Facebook borked
    "fb_xd_fragment",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
    // reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268
    "bmi_SafeAddOnload",
    "EBCallBackMessageReceived",
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    "conduitPage",

    // See https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
    // perhaps somewhat caused by antd
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  integrations: [
    Sentry.replayIntegration(),
    new CohereSentry(),
    posthog.sentryIntegration({
      organization: "humanloop",
      projectId: 5370964, // Last digits of DSN
      severityAllowList: ["error", "info"], // optional: here is set to handle captureMessage (info) and captureException (error)
    }),
  ],

  // Sample transactions for performance profiling
  tracesSampleRate: 0.2,
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  // See https://blog.sentry.io/local-variables-for-nodejs-in-sentry
  // Although it might not work with ESM still, see:
  // https://docs.sentry.io/platforms/node/configuration/integrations/default-integrations/
  includeLocalVariables: true,
  integrations(integrations) {
    // See https://docs.sentry.io/platforms/javascript/configuration/integrations/#removing-a-default-integration
    // Recommended to remove Breadcrumbs if concerned about PII
    return integrations.filter((integration) => integration.name !== "Breadcrumbs");
  },
});
